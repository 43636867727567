import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { TYPO } from "../config"
import SectionItem from "../elements/SectionItem"
import SkillItem from "../elements/Skillitem"
import Typo from "../elements/Typo"

import Plx from "react-plx"
import HR from "../elements/Hr"

const Home: React.FC = () => {
	// GTM

	// SEO Meta Tags

	const meta = {
		title: "React Entwickler Hamburg - Björn Rühle",
		canonical: "https://webstanding.de",
		meta: {
			name: {
				// robots: "noindex, nofollow",
			},
		},
	}

	useEffect(() => {}, [])

	const parallaxData = [
		{
			start: 0,
			end: "footer",
			properties: [
				{
					startValue: 0,
					endValue: 180,
					property: "rotate",
				},
			],
		},
	]

	return (
		<DocumentMeta {...meta}>
			<Plx className="fixed left-0 top-0 w-full h-full z-0" parallaxData={parallaxData}>
				<div
					style={{ transform: "scale(2.5)" }}
					className="bg-[url('/src/images/pattern.svg')]  bg-no-repeat absolute left-0 top-0 w-full h-full"
				></div>
			</Plx>

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4 w-full">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto px-4 items-center">
						<ul className="list-skills order-2 md:order-1">
							<SkillItem>Individuelle Website-Lösungen</SkillItem>
							<SkillItem>Geschäft transformieren</SkillItem>
							<SkillItem>Inspirierende Kundenerlebnisse</SkillItem>
							<SkillItem>Exzellente Technologien</SkillItem>
							{/* <SkillItem>REACT DEVELOPMENT</SkillItem>
							<SkillItem>TYPESCRIPT</SkillItem>
							<SkillItem>ONPAGE PERFORMANCE</SkillItem>
							<SkillItem>SUITCSS / Tailwind</SkillItem>
							<SkillItem>
								PROGR<span className="hidden sm:inline">ESSIVE</span>
								<span className="inline sm:hidden">.</span> ENHANCEMENT
							</SkillItem>
							<SkillItem>AGILE WORKFLOWS</SkillItem> */}
						</ul>
						<div className="order-1 md:order-2">
							<Typo variant={TYPO.H1} className="text-left md:text-center">
								{/* Meine Skills */}
								Björn Rühle
							</Typo>
							<img
								className="rounded-[50%] border-2 border-black mx-auto max-w-[70%]"
								src="/images/bjoern_ruehle.jpg"
								style={{ boxShadow: "0px 0px 0px 4px #ffffff" }}
								alt="Ich"
								width="1000"
								height="954"
							/>
						</div>
					</div>
				</div>
			</SectionItem>

			<HR />

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4">
					<Typo variant={TYPO.H1}>Gewusst wie</Typo>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Modularer Aufbau</Typo>
						<ul>
							<SkillItem small={true}>SMACSS</SkillItem>
							<SkillItem small={true}>BEM</SkillItem>
							<SkillItem small={true}>SUIT</SkillItem>
							<SkillItem small={true}>CSS</SkillItem>
							<SkillItem small={true}>Typescript</SkillItem>
							<SkillItem small={true}>React JS </SkillItem>
							<SkillItem small={true}>Tailwind</SkillItem>
							<SkillItem small={true}>Social Sharing</SkillItem>
						</ul>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Responsive Web Design</Typo>
						<ul>
							<SkillItem small={true}>Cross Browser Testing </SkillItem>
							<SkillItem small={true}>Emulator </SkillItem>
							<SkillItem small={true}>Real Device Testing</SkillItem>
						</ul>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Attraktive UX</Typo>
						<ul>
							<SkillItem small={true}>Styleguides </SkillItem>
							<SkillItem small={true}>BITV </SkillItem>
							<SkillItem small={true}>Transitions </SkillItem>
							<SkillItem small={true}>Animationen</SkillItem>
						</ul>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Agile Entwicklungs­methoden</Typo>
						<ul>
							<SkillItem small={true}>Scrum Kanban </SkillItem>
							<SkillItem small={true}>Continuous Delivery</SkillItem>
						</ul>
					</div>
				</div>
			</SectionItem>

			<HR />

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4">
					<Typo variant={TYPO.H1}>Kunden & Brands</Typo>
					<div className="grid grid-cols-2 md:grid-cols-4 gap-0 md:gap-8">
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-1.png"
								alt="Kunde 1"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-2.png"
								alt="Kunde 2"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-3.png"
								alt="Kunde 3"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-4.png"
								alt="Kunde 4"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
					</div>
				</div>
			</SectionItem>

			<HR />

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4">
					<div className="grid md:grid-cols-[250px_1fr] gap-8 items-center">
						<svg
							className="hidden md:block"
							focusable="false"
							aria-hidden="true"
							viewBox="0 0 24 24"
							data-testid="ContactMailIcon"
						>
							<path
								fill="#ffffff"
								d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
							/>
						</svg>

						<div>
							<Typo variant={TYPO.H1}>Kontakt</Typo>

							<svg
								className="block md:hidden  mx-auto max-w-[60%]"
								focusable="false"
								aria-hidden="true"
								viewBox="0 0 24 24"
								data-testid="ContactMailIcon"
							>
								<path
									fill="#ffffff"
									d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
								/>
							</svg>
							<p className="text-white text-xl mb-4 md:mb-8 ">
								Sie haben eine Frage oder benötigen individuelle Unterstützung bei Ihrem Projekt? Dann schreiben Sie mir
								gerne eine Nachricht mit Ihrem Anliegen!
							</p>
							<a
								href="mailto:moin@webstanding.de"
								className="rounded-lg border-2 border-white text-white text-lg px-8 py-4 inline-block"
							>
								Anfrage senden
							</a>
						</div>
					</div>
				</div>
			</SectionItem>
		</DocumentMeta>
	)
}
export default Home
